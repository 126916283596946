<template>
  <en-drawer :title="`返修工单(${form.data.serialNo ?? ''})`" :model-value="modelValue" @close="$emit('update:model-value', false)" :size="800">
    <en-form :model="form.data" label-position="left">
      <en-form-item label="项目">
        <en-table
          :data="form.data.serviceMaintenanceExternalReworks"
          :loading="form.loading"
          @selection-change="form.serviceMaintenanceExternalReworks.selection.change"
          class="w-full"
        >
          <en-table-column
            type="selection"
            :selectable="
              (row: EnocloudServiceDefinitions['ServiceMaintenanceExternalReworkDto']) =>
                row.serviceMaintenance?.maintenance?.spraySurface?.split('_')[1] !== 'S'
            "
          ></en-table-column>
          <en-table-column label="项目名称" prop="serviceMaintenance.name"></en-table-column>
          <en-table-column label="返修缘由">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceExternalReworkDto'] }">
              <select-maintain
                v-model="row.reworkType"
                :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['RWKTP']) }"
                :props="{ label: 'message', value: '' }"
                value-key="code"
                :disabled="
                  !form.serviceMaintenanceExternalReworks.selection.data
                    .map((item) => item.serviceMaintenance?.id)
                    .includes(row.serviceMaintenance?.id)
                "
                class="w-full"
              ></select-maintain>
            </template>
          </en-table-column>
        </en-table>
      </en-form-item>
      <en-form-item label="备注" prop="reworkComment">
        <en-input v-model="form.data.reworkComment" type="textarea"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button :disabled="form.loading" @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" :disabled="form.loading" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es'
import { EnMessage } from '@enocloud/components'

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceDto']>,
    service: Object as PropType<EnocloudServiceDefinitions['ServiceDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler(value) {
        if (value && this.data?.id) {
          this.form.data.id = this.data.id
          await this.form.get()
          this.form.data.serviceMaintenanceExternalReworks = this.form.data.maintenances
            ?.filter((item) => item.warrantied?.value)
            .map((item) => {
              return {
                reworkType: { code: 'CF', message: '施工故障', description: '', type: '' },
                serviceMaintenance: { ...item }
              } as EnocloudServiceDefinitions['ServiceMaintenanceExternalReworkDto']
            })
        }

        if (!value) {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/:serviceId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.data?.id]
            }
          },
          rework: {
            action: 'POST /enocloud/service/rework',
            loading: true,
            params(params) {
              params.payload = cloneDeep(this.service!)
              params.payload.reworkService = cloneDeep(this.form.data)
              params.payload.reworkService.serviceMaintenanceExternalReworks = this.form.serviceMaintenanceExternalReworks.selection.data
            }
          }
        },
        children: {
          serviceMaintenanceExternalReworks: {
            selection: {
              data: [] as EnocloudServiceDefinitions['ServiceMaintenanceExternalReworkDto'][],
              change(rows: EnocloudServiceDefinitions['ServiceMaintenanceExternalReworkDto'][]) {
                this.form.serviceMaintenanceExternalReworks.selection.data = rows
              }
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            if (!this.form.serviceMaintenanceExternalReworks.selection.data.length) {
              return EnMessage.warning('请选择返工项目')
            }
            await this.form.rework()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        }
      }
    }
  }
})
</script>
